<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-head">
				<video v-if="liveInfo.pre_video_url" :src="liveInfo.pre_video_url" controls="controls" width="100%"></video>
				<img :src="liveInfo.cover0" @error="imageLoadErrorLive" alt="" v-else>
				<div class="prev">
					<el-tag effect="dark">{{$t('mobile.live.tag1')}}</el-tag>
				</div>
			</div>
			<div class="mpage-section">
				<div class="datetime-item">
					<div class="item-text">{{liveTimestamp}}</div>
					<div class="item-bar">
						<span class="text" v-if="liveInfo.status < 0">{{$t('mobile.live.text1')}}</span>
						<el-button v-else-if="liveInfo.aliyun_status == 1"
							@click="$router.push({name:'mLiveInfo',params:{id:$route.params.id}})" size="mini">{{$t('mobile.actions.broadcast')}}</el-button>
						<el-button v-else-if="liveInfo.aliyun_status == 2"
							@click="$router.push({name:'mLivePlayback',params:{id:$route.params.id}})" size="mini">{{$t('mobile.actions.playback')}}
						</el-button>
					</div>
				</div>
				<div class="mstore-item">
					<div class="item-info">
						<img :src="storeInfo.store_logo?storeInfo.store_logo:$imageErrorLogo" class="avatar" @error="imageLoadErrorLogo" alt="">
						<div class="item-box">
							<div class="name">{{liveInfo.title}}</div>
							<div class="text">{{storeInfo.store_name}}{{$t('mobile.live.text2')}}</div>
						</div>
					</div>
					<div class="item-bar">
						<div class="btn disabled" @click="handleUnFollow" v-if="liveInfo.is_collect==1">{{$t('mobile.actions.unfollow')}}</div>
						<div class="btn" @click="handleFollow" v-else>{{'+'+$t('mobile.actions.follow')}}</div>
					</div>
				</div>
				<div class="mstore-desc">{{liveInfo.intro?liveInfo.intro:$t('mobile.live.text3')}}</div>
			</div>
			<div class="mpage-section">
				<div class="sction-title">
					<span class="name">{{$t('mobile.live.text4')}}</span>
					<router-link :to="{name: 'mLiveGoods',params:{id:$route.params.id}}" class="next">{{$t('mobile.actions.moreBaby')}}<i class="el-icon-arrow-right"></i></router-link>
				</div>
				<div class="mgoods-list">
					<router-link class="item" v-for="(item,index) in liveGoods" :key="index" :to="{name:'mGoodsInfo',params:{id:item.goodsId},query:{live:liveInfo.live_id}}">
						<div class="item-pic"><img :src="item.defaultImage" @error="imageLoadErrorGoods" alt=""></div>
						<div class="item-main">
							<div class="name">{{item.goodsName}}</div>
							<div class="price">￥<span>{{item.price}}</span></div>
						</div>
					</router-link>
				</div>
			</div>
			<div class="mpage-section">
				<div class="sction-title">
					<span class="name">{{$t('mobile.live.text5')}}</span>
					<router-link :to="{name: 'mLiveListNotice'}" class="next">{{$t('mobile.live.text6')}}<i class="el-icon-arrow-right"></i></router-link>
				</div>
				<div class="mlive-list">
					<router-link class="item" v-for="(item,index) in liveList" :key="index" :to="{name:'mLiveEdit',params:{id:item.live_id}}">
						<img :src="item.cover0" @error="imageLoadErrorLive" alt="" class="pic">
						<div class="item-main">
							<div class="name">
								<el-tag effect="dark">{{$t('mobile.live.tag1')}}</el-tag>
								<span>{{item.title}}</span>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { fetchShopInfo, onCollect, onUncollect, browseStore } from '@/api/mobile/store';
	import { getLiveList, getLiveGoods, fetchLiveNotice } from '@/api/mobile/live';
	import { getToken } from '@/utils/auth';
	export default {
		data() {
			return {
				liveInfo: {},
				storeInfo: {},
				liveGoods: [],
				liveList: [],
				liveTimestamp: '00天00时00分00秒',
			}
		},
		mounted() {
			this.getLiveInfo();
			this.getLiveGoods();
			this.getLivelist();
		},
		methods: {
			imageLoadErrorGoods(e) {
				e.target.src = this.$imageErrorGoods;
			},
			imageLoadErrorLive(e) {
				e.target.src = this.$imageErrorLive;
			},
			imageLoadErrorLogo(e) {
				e.target.src = this.$imageErrorLogo;
			},
			getLiveInfo(){
				let param = {
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				fetchLiveNotice(param).then(async response => {
					const { data } = response
					this.liveInfo = data
					setTimeout(async () => {
						await browseStore({ live_id: data.live_id, store_id: data.store_id, from: 1 })
					},3000)
					this.getStoreInfo(data.store_id)
					if (parseInt(response.data.status) == 0) {
						setInterval(() => {
							this.onLiveTimestamp()
						}, 1000);
					}
				}).catch(() => {
					this.$router.push({name:'mLiveListNotice'});
				})
			},
			onLiveTimestamp() {
				let rowDate = parseInt(new Date() / 1000);
				let longTime = this.liveInfo.settime - rowDate;
				if (longTime > 0) {
					let dd = longTime / 86400;
					let longH = longTime % 86400;
					let hh = longH / 3600;
					let longM = longH % 3600;
					let mm = longM / 60;
					let ss = longM % 60;
					dd = parseInt(dd) < 10 ? '0' + parseInt(dd) : parseInt(dd);
					hh = parseInt(hh) < 10 ? '0' + parseInt(hh) : parseInt(hh);
					mm = parseInt(mm) < 10 ? '0' + parseInt(mm) : parseInt(mm);
					ss = parseInt(ss) < 10 ? '0' + parseInt(ss) : parseInt(ss);
					this.liveTimestamp = dd + "天" + hh + "时" + mm + "分" + ss + "秒";
				} else {
					this.liveTimestamp = '00天00时00分00秒';
				}
			},
			getStoreInfo(){
				const id = this.liveInfo.store_id;
				fetchShopInfo({store_id: id}).then(response => {
					this.storeInfo = response.data;
				})
			},
			getLiveGoods(){
				let param = {
					page_index: 0,
					page_size: 4,
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				getLiveGoods(param).then(response => {
					this.liveGoods = response.data.list;
				})
			},
			getLivelist(){
				let param = {
					isall: 1,
					issubscribe: 1,
					status: 0,
					starttime: 1,
					page_index: 0,
					page_size: 4,
					live_id: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				getLiveList(param).then(response => {
					this.liveList = response.data.list;
				})
			},
			handleFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'live',
					followId: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onCollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.successMsg.follow'),
						customClass: 'wap-message',
						type: 'success'
					});
					// this.getStoreInfo();
					this.$router.go(0)
				})
			},
			handleUnFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'live',
					followId: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onUncollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.cancelMsg.follow'),
						customClass: 'wap-message',
						type: 'warning'
					});
					// this.getStoreInfo();
					this.$router.go(0)
				})
			},
		},
		watch: {
			'$route': {
				handler: function(val, oldVal) {
					Object.assign(this.$data, this.$options.data());
					this.getLiveInfo();
					this.getLiveGoods();
					this.getLivelist();
				},
				deep: true
			},
		}
	}
</script>
<style lang="scss" scoped>
	.mhome-wrap {
		.mhome-head {
			width: 100%;
			height: 12rem;
			position: relative;
			img,video {
				width: 100%;
				height: 100%;
			}
			img {
				object-fit: cover;
			}
			.prev {
				top: 0.32rem;
				left: 0.32rem;
				position: absolute;
				.el-tag {
					font-size: 0.384rem;
					height: 0.768rem;
					line-height: 0.768rem;
					border: none;
					padding: 0 0.32rem;
					border-radius: 0.128rem;
				}
			}
		}
		.mpage-section {
			margin-bottom: 0.32rem;
			background-color: #FFFFFF;
			.sction-title {
				height: 1.28rem;
				padding-right: 0.384rem;
				border-left: 0.128rem solid red;
				background-color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.name {
					font-size: 0.448rem;
					padding-left: 0.256rem;
				}
				.next {
					color: red;
					font-size: 0.416rem;
					i {
						margin-left: 0.128rem;
					}
				}
			}
		}
		.datetime-item {
			height: 0.896rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.256rem 0.384rem;
			background-color: #3ea6ff;
			.item-text {
				color: #fff;
				font-size: 0.416rem;
			}
			.item-bar {
				.text {
					color: red;
					font-size: 0.416rem;
					white-space: nowrap;
				}
				.el-button {
					height: 0.896rem;
					font-size: 0.416rem;
					padding: 0.192rem 0.384rem;
					line-height: 0.544rem;
					border-radius: 0.128rem;
					border: none;
				}
			}
		}
		.mstore-item {
			display: flex;
			padding: 0.32rem 0.384rem;
			align-items: center;
			justify-content: space-between;
			.item-info {
				display: flex;
				.avatar {
					width: 1.088rem;
					height: 1.088rem;
					border-radius: 50%;
				}
				.item-box {
					margin-left: 0.192rem;
					.name {
						color: #333333;
						font-size: 0.416rem;
						height: 0.544rem;
						line-height: 0.544rem;
					}
					.text {
						font-size: 0.384rem;
						height: 0.544rem;
						line-height: 0.544rem;
						color: #999999;
					}
				}
			}
			.item-bar {
				.btn {
					height: 0.768rem;
					line-height: 0.768rem;
					border: 1px solid #f40;
					color: #f40;
					display: inline-block;
					font-size: 0.416rem;
					padding: 0 0.256rem;
					border-radius: 0.416rem;
				}
				.btn.disabled {
					color: #999999;
					border-color: #999999;
				}
			}
		}
		.mstore-desc {
			color: #666666;
			border-top: 1px solid #E5E5E5;
			padding: 0.384rem;
			font-size: 0.416rem;
			line-height: 1.5;
		}
		.mgoods-list {
			overflow: hidden;
			.item {
				float: left;
				width: 50%;
				.item-pic {
					width: 100%;
					height: 6rem;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.item-main {
					padding: 0.32rem;
					.name {
						color: #333333;
						font-size: 0.416rem;
						height: 1.088rem;
						line-height: 0.544rem;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.price {
						color: #f40;
						font-size: 0.352rem;
						height: 0.544rem;
						line-height: 0.544rem;
						span {
							font-size: 0.448rem;
						}
					}
				}
			}
		}
		.mlive-list {
			overflow: hidden;
			.item {
				width: 50%;
				float: left;
				position: relative;
				.pic {
					width: 100%;
					height: 6rem;
					object-fit: cover;
				}
				.item-main {
					left: 0;
					bottom: 0;
					width: 100%;
					padding: 0.32rem;
					box-sizing: border-box;
					position: absolute;
					.name {
						color: #FFFFFF;
						font-size: 0.416rem;
						height: 0.544rem;
						line-height: 0.544rem;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						.el-tag {
							border: none;
							height: 0.512rem;
							line-height: 0.512rem;
							font-size: 0.32rem;
							padding: 0 0.192rem;
							border-radius: 0.256rem;
							margin-right: 0.192rem;
						}
					}
				}
			}
		}
	}
</style>